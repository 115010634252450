import { GetStaticPropsContext, GetStaticPropsResult } from 'next'

import Error4xx from '@/modules/errors/pages/Error4xx'

export async function getStaticProps(
  _: GetStaticPropsContext,
): Promise<GetStaticPropsResult<{}>> {
  return {
    props: {},
  }
}

export default Error4xx
