import { Fragment } from 'react'
import { NextSeo } from 'next-seo'
import NextLink from 'next/link'
import Image from 'next/image'
import { useTheme } from '@emotion/react'

import { Button } from '@/components/Button'

import ErrorImage from '@/images/404-error-bro@2x.png'

function Error400(): React.ReactElement {
  const theme = useTheme()

  return (
    <Fragment>
      <NextSeo title="404 - Not found" />
      <div
        css={theme.mq({
          display: 'flex',
          width: '--sizes-full',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <div
          css={theme.mq({
            maxWidth: '850px',
            margin: '0 auto',
            padding: '--space-4',
          })}
        >
          <div
            css={theme.mq({
              gridTemplateColumns: { base: '1fr', md: 'max-content 1fr' },
              gridGap: '--space-4',
              alignItems: 'center',
            })}
          >
            <div
              css={theme.mq({
                marginRight: '--space-8',
                textAlign: 'center',
              })}
            >
              <Image src={ErrorImage} width="430px" height="450px" />
            </div>
            <div
              css={theme.mq({
                display: 'grid',
                gridGap: '--space-1',
              })}
            >
              <h2
                css={theme.mq({
                  fontSize: '--fontSizes-4xl',
                  fontWeight: '--fontWeights-semibold',
                })}
              >
                Oops!
              </h2>
              <p
                css={theme.mq({
                  fontSize: '--fontSizes-3xl',
                })}
              >
                This page you looking for is not found.
              </p>
              <NextLink href="/" passHref>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  sizing="lg"
                  sx={{
                    marginTop: 4,
                  }}
                >
                  Back to homepage
                </Button>
              </NextLink>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Error400
